import { Injectable } from '@angular/core';
import {catchError, defer, mergeMap, Observable, of} from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  GetNonceResponse,
  GetPersonalDataResponse,
  RegisterEmailResponse,
  UpdatePersonalDataRequest, UserResponse,
  VerifyEmailResponse,
} from '../store';
import {CreateCustomerResponse} from "../../features/mvp/store";
import {WalletStore} from "@heavy-duty/wallet-adapter";
import {
  clusterApiUrl,
  ComputeBudgetProgram,
  ConfirmOptions,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram
} from "@solana/web3.js";
import { IdlAccounts, Program } from "@coral-xyz/anchor";
import {IDL, ILDType} from "./abi";
import * as anchor from '@coral-xyz/anchor';
import { AnchorProvider, setProvider } from "@coral-xyz/anchor";
import {
  getAssociatedTokenAddress,
  TOKEN_2022_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {MerkleTree} from "merkletreejs";
import {keccak_256} from "@noble/hashes/sha3";
import {PhantomSessionData} from "../models/phantom.models";
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  message = `We need you to sign this message to verify the wallet ownership`;

  constructor(private http: HttpClient) {}

  getBotCategories(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/categories/' + id);
  }

  createCustomer(
    login: string,
    password: string,
    name: string,
  ): Observable<CreateCustomerResponse> {
    return this.http.post<CreateCustomerResponse>(
      `${environment.apiUrl}/customer/create/${environment.botId}`,
      {
        login: login,
        password: password,
        profile: {
          name,
        },
      },
    );
  }

  registerEmail(email: string): Observable<RegisterEmailResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.post<RegisterEmailResponse>(
      `${environment.apiUrl}/person/email`,
      { email },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  verifyEmail(hash: string): Observable<VerifyEmailResponse> {
    const apiUrl = environment.apiUrl;
    return this.http.post<VerifyEmailResponse>(
      `${apiUrl}/person/email/verify`,
      { hash },
    );
  }

  getPersonalData(): Observable<GetPersonalDataResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.get<GetPersonalDataResponse>(
      `${environment.apiUrl}/person/me`,
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  updatePersonalData(
    address: string,
    telephone: string,
    mobile: string,
  ): Observable<UpdatePersonalDataRequest> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.put<UpdatePersonalDataRequest>(
      `${environment.apiUrl}/person/me`,
      {
        attributes: {
          address: address,
          telephone: telephone,
          mobile: mobile,
        },
      },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }



  getNonce(address: string, blockchain: string): Observable<GetNonceResponse> {
    return this.http.post<GetNonceResponse>(
      `${environment.apiUrl}/crypto-wallet/verification/nonce`,
      {
        address: address,
        blockchain: blockchain,
      },
    );
  }


  getMessage(address: string, nonce: string) {
    return `localhost:4200 wants you to sign in with your Solana account:\n${address}\n\nNonce: ${nonce}`;
  }

  getCryptoToken(nonce: string, walletStore: WalletStore, address: string): any {
    const message = this.getMessage(address, nonce);

    const encodedMessage = new TextEncoder().encode(message);
    return walletStore.signMessage(encodedMessage);
  }

  getToken(
    blockchain: string,
    address: string,
    signature: any,
    nonce: string
  ): Observable<any> {
    return this.http.post(`${environment.apiUrl}/crypto-wallet`, {
      bot_id: environment.botId,
      blockchain: blockchain,
      address: address,
      signature: signature,
      message: this.getMessage(address, nonce),
    })
  }

  getUser() {
    return this.http.get<UserResponse>(
      `${environment.apiUrl}/person/me`, {
        headers: {'X-Access-Token': localStorage.getItem('token') || ''}
      })
  }

  getCustomerToken() {
    return this.http.get(`${environment.apiUrl}/person/get/customer?ensure=true`,  {
      headers: {'X-Access-Token': localStorage.getItem('token') || ''}
    })
  }

  getLoyaltyData() {
    return this.http.get(`${environment.apiUrl}/tool/${environment.botId}/affiliate-program/data?merchant_id=${environment.merchant_id}`, {
      headers: {'X-Access-Token': localStorage.getItem('customerToken') || ''}
    })
  }

  getMerkleProofForAddress(publicKey: string, amount: number): Observable<any> {
    const body = {
      "value": {
        "address": publicKey,
        "amount": amount
      }
    };
    return this.http.post(`${environment.apiUrl}/tool/${environment.botId}/merkle-tree/proof`, body,{
      headers: {'X-Access-Token': localStorage.getItem('customerToken') || ''}
    });

    // const whitelist = [
    //   { address: 'BXwdyVzds4g5ysrSWCMC4E82axfYsZEjadDadV4jXX4M', amount: 100 },
    //   { address: 'u2j6N7XpZi6u1FNfrAhqp9BaBTvGAuWVmu5jXinmgHS', amount: 200 },
    // ]
    // const data = whitelist.map(({ address, amount }) => {
    //   return Buffer.from([
    //     ...Array.from(new PublicKey(address).toBuffer()),
    //     ...new anchor.BN(amount).toArray('le', 8)
    //   ])
    // });
    // const tree = new MerkleTree(data.map(keccak_256), keccak_256, {
    //   sortPairs: true,
    // });
    // const amountInner = new anchor.BN(amount);
    // const leaf = Buffer.from([
    //   ...Array.from(new PublicKey(publicKey).toBuffer()),
    //   ...amountInner.toArray('le', 8)
    // ]);
    // const proof = tree.getProof(Buffer.from(keccak_256(leaf))).map((proof) => Array.from(proof.data));
    // return of(proof);
  }

  async startContract(walletInner: any, walletAddress: string, points: number, proof: any, isMobile: boolean) {
    const opts: ConfirmOptions = { preflightCommitment: 'processed' };
    const connection = new Connection(clusterApiUrl("devnet"), 'processed');
    const providerInner = new AnchorProvider(connection, walletInner, opts);
    anchor.setProvider(providerInner);
    const program = new Program<ILDType>(IDL, anchor.getProvider());

    const wallet = new PublicKey(environment.contractWallet);
    const userPublicKey = providerInner.publicKey;
    const mint = new PublicKey(environment.tokenAddress);
    const amount = new anchor.BN(points);
    const [distributor] = PublicKey.findProgramAddressSync(
      [
        Buffer.from('distributor'),
        mint.toBuffer(),
        wallet.toBuffer(),
      ],
      program.programId,
    );
    const [claimStatus] = PublicKey.findProgramAddressSync(
      [
        Buffer.from('claim_status'),
        userPublicKey.toBuffer(),
      ],
      program.programId,
    );
    const userTokenAccount = await getAssociatedTokenAddress(
      mint,
      userPublicKey,
      false,
      TOKEN_2022_PROGRAM_ID,
    );
    // const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
    //   units: 300,
    // });
    //
    // const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
    //   microLamports: 20000,
    // });
    const accounts = {
      distributor,
      claimStatus,
      mint,
      user: userPublicKey,
      userTokenAccount,
      tokenProgram: TOKEN_2022_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    }

   const account = program.account as any;
    // let status = await account['claimStatus'].fetch(claimStatus);
    // console.log(status);
    console.log(amount);
    console.log(proof);
    if (!isMobile) {

      return  await program.methods.claim(
         amount,
         proof,
       ).accounts(accounts)
         // .signers([kp])
         // .simulate();
         // .rpc({ skipPreflight: true });
         .rpc({ skipPreflight: true });
   } else {
      const kp = Keypair.generate();
      // setProvider(providerInner);
      localStorage.setItem('kp', JSON.stringify(kp));
      return await program.methods.claim(
        amount,
        proof,
      ).accounts(accounts)
        .signers([kp])
        .transaction();
   }
  }






}
