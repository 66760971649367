import { NgModule } from '@angular/core';
import {NgxsModule} from "@ngxs/store";
import {environment} from "../../environments/environment";
import {CoreState} from "./store/core.state";
import { MatIconModule } from '@angular/material/icon';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {SharedModule} from "../shared/shared.module";
import {HdWalletAdapterModule, provideWalletAdapter} from "@heavy-duty/wallet-adapter";
import {HdWalletAdapterMaterialModule} from "@heavy-duty/wallet-adapter-material";
import {NgxsLoggerPluginModule} from "@ngxs/logger-plugin";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    NgxsModule.forRoot([CoreState]),
    NgxsLoggerPluginModule.forRoot({ disabled: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: CoreState,
    }),
    MatIconModule,
    MatSnackBarModule,
    HdWalletAdapterMaterialModule,
    HdWalletAdapterModule.forRoot()
  ],
  providers: [ provideWalletAdapter()],
})
export class CoreModule {}
