import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: 'landing', pathMatch: 'full'},
  {path: '',
    loadChildren: () =>
      import('./features/mvp/mvp.module').then((m) => m.MvpModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      // enableTracing: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
