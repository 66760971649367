import { Action, State, StateContext, NgxsOnInit } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  CoreStateModel,
  RegisterEmailResponse,
  VerifyEmailResponse, WalletStateModel,
} from './core-state.model';
import { environment } from '../../../environments/environment';
import {catchError, defer, mergeMap, Observable, tap, throwError} from 'rxjs';
import {
  ConnectWallet, DeleteContractMobile, DeleteStateMobile,
  DisconnectWallet, ExecuteProgramMethod,
  GetLoyaltyData, GetMerkleProof, GetNonce,
  GetUser, SecureConnect, SetSuccessStatus,
} from './core.actions';
import { CoreService } from '../services/core.service';
import { CreateCustomerResponse } from '../../features/mvp/store';
import { MatSnackBar } from '@angular/material/snack-bar';
// import {PhantomService} from "../services/phantom.service";
// import {WalletStore} from "@heavy-duty/wallet-adapter";
import bs58 from "bs58";
// import {PhantomDeeplinkingService} from "../services/phantom-deeplinking.service";
import {web3} from "@project-serum/anchor";

@State<CoreStateModel>({
  name: 'core'
})

@Injectable()
export class CoreState {
  constructor(
    private coreService: CoreService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    // private phantomService: PhantomService,
    // private phantomDeepLink: PhantomDeeplinkingService
  ) {}

  @Action(ConnectWallet)
  connectWallet(
    {patchState}: StateContext<CoreStateModel>,
    { publicKey}: ConnectWallet,
  ) {
     patchState({
      publicKey: publicKey,
    });
  }

  @Action(DisconnectWallet)
  disconnectWallet(
    {patchState}: StateContext<CoreStateModel>,
    { }: ConnectWallet,
  ) {
    localStorage.clear();
    sessionStorage.clear();
    patchState({
      walletAddress: undefined,
      publicKey: undefined,
      wallet: undefined,
      customerToken: undefined,
      customer: undefined,
      profile: undefined,
      nonce: undefined,
      loyaltyData: undefined,
      markleStatus: undefined,
      contract: undefined
    });
  }


  @Action(SecureConnect)
  secureConnect(
    {patchState}: StateContext<CoreStateModel>,
    { address, walletStore }: SecureConnect,
  ) {
   return this.coreService.getNonce(address, 'solana')
     .pipe(
       mergeMap((res: any) => {
       localStorage.setItem('claimAddress', address);
       localStorage.setItem('nonce', res.nonce);
       return this.coreService.getCryptoToken(res.nonce, walletStore, address);
     })).pipe(
       mergeMap((signature: any) => {
       return this.coreService.getToken('solana', address, bs58.encode(signature),localStorage.getItem('nonce') || '');
     })).pipe(tap((res: any) => {
       if (res.status) {
         patchState({customerToken: res.token});
         localStorage.setItem('token', res.token);
       }
    }), catchError((err: any) => {
       this.zone.run(() => {
         this.snackBar.open('error' + err.toString(), 'X', {
           duration: 4000,
           horizontalPosition: 'center',
           verticalPosition: 'bottom',
         });
       });
       return throwError(err);
     }))
     .pipe(
      mergeMap(() => {
        return this.coreService.getUser()
      })
     ).pipe(tap((res: any) => {
       if (res.status) {
         localStorage.setItem('name',  res?.person?.profile.name);
         patchState({profile: res?.person?.profile});
       }
     }))
  }

   @Action(GetNonce)
    getNonce(
      {patchState}: StateContext<CoreStateModel>,
      { address }: GetNonce,
    ) {
     return this.coreService.getNonce(address, 'solana').pipe(tap((res: any) => {
         if (res.status) {
           patchState({nonce: res.nonce});
           localStorage.setItem('nonce', res.nonce);
         }
      }));
    }
    @Action(GetLoyaltyData)
    getLoyaltyData(
      {patchState}: StateContext<CoreStateModel>,
      {  }: GetLoyaltyData,
    ) {
     return this.coreService.getCustomerToken().pipe(
       mergeMap((res: any) => {
         localStorage.setItem('customerToken', res?.customer?.token)
         patchState({customer: res.customer});
         return this.coreService.getLoyaltyData().pipe(
           tap((res: any) => {
           if (res.status) {
             patchState({loyaltyData: res?.data});
           }
         }))
       })
     )
    }

   @Action(GetUser)
    getUser(
      {patchState, dispatch}: StateContext<CoreStateModel>,
      {address, signature  }: GetUser,
    ) {
    return this.coreService.getToken('solana', address, signature,
      localStorage.getItem('nonce') || '').pipe(
        tap((res: any) => {
    if (res.status) {
    patchState({customerToken: res.token});
    localStorage.setItem('token', res.token);
    }
    }),
      catchError((err) => {
      this.zone.run(() => {
        this.snackBar.open('error' + err.toString(), 'X', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      });
        return throwError(err);
      }))
    .pipe(
      mergeMap(() => {
      return this.coreService.getUser()
    })
    ).pipe(tap((res: any) => {
      if (res.status) {
        localStorage.setItem('name',  res?.person?.profile.name)
        patchState({profile: res?.person?.profile});
      }
     }))
    }

  // clear variables in store
  @Action(DeleteStateMobile)
  deleteStateMobile(
    {patchState}: StateContext<CoreStateModel>,
    { }: DeleteStateMobile,
  ) {
    localStorage.setItem('nonce', '');
    patchState({
      nonce: undefined,
      customerToken: undefined,
    });
  }
  @Action(SetSuccessStatus)
  setSuccessStatus(
    {patchState}: StateContext<CoreStateModel>,
    { }: SetSuccessStatus,
  ) {
    patchState({
      successStatus: true
    });
  }
  @Action(DeleteContractMobile)
  deleteContractMobile(
    {patchState}: StateContext<CoreStateModel>,
    { }: DeleteContractMobile,
  ) {
    patchState({
      markleStatus: null,
      contract: null
    });
  }

  @Action(GetMerkleProof)
  getMerkleProof(
    {patchState, dispatch}: StateContext<CoreStateModel>,
    { wallet, address, points, isMobile }: GetMerkleProof,
  ) {
    return this.coreService.getMerkleProofForAddress(address, points).pipe(
    tap((res: {status: boolean, proof: string[]}) => {
      if (res.status) {
      const proofTransformed = res.proof.map((item) => Array.from(Buffer.from(item, 'hex')));

      patchState({
          markleStatus: proofTransformed,
        });
        dispatch(new ExecuteProgramMethod(wallet, address, points, proofTransformed, isMobile))
      } else {
        this.zone.run(() => {
          this.snackBar.open('Your address is not in the whitelist', 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        });
      }
    }), catchError((err: any) => {
        this.zone.run(() => {
          this.snackBar.open('Your address is not in the whitelist', 'X', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        });
        return throwError(err);
      }));
  }

  @Action(ExecuteProgramMethod)
  executeProgramMethod(
    {patchState}: StateContext<CoreStateModel>,
    { wallet,  address, points, proof, isMobile }: ExecuteProgramMethod
  ) {
    return defer(() => this.coreService.startContract( wallet, address, points, proof, isMobile)).pipe(
    tap((res: any) => {
      console.log(res);
      if (isMobile) {
        patchState({
          contract: res
        });
      } else {
        patchState({
          successStatus: true
        });
      }
    }), catchError((err: any) => {
        this.zone.run(() => {
          this.snackBar.open('error' + err.toString(), 'X', {
            duration:4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        });
        return throwError(err);
      }));
  }
}
