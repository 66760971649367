import {environment} from "../../../environments/environment";

export const IDL: ILDType  = {
  "address": "Ck8XJkLGS7buVBSFBdjJdo5HbCQvikLs5RFJgnW1AWza",
  "metadata": {
    "name": "token_distribution",
    "version": "0.1.0",
    "spec": "0.1.0",
    "description": "Created with Anchor"
  },
  "instructions": [
    {
      "name": "claim",
      "discriminator": [
        62,
        198,
        214,
        193,
        213,
        159,
        108,
        210
      ],
      "accounts": [
        {
          "name": "distributor",
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  100,
                  105,
                  115,
                  116,
                  114,
                  105,
                  98,
                  117,
                  116,
                  111,
                  114
                ]
              },
              {
                "kind": "account",
                "path": "distributor.mint",
                "account": "Distributor"
              },
              {
                "kind": "account",
                "path": "distributor.authority",
                "account": "Distributor"
              }
            ]
          }
        },
        {
          "name": "claim_status",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  99,
                  108,
                  97,
                  105,
                  109,
                  95,
                  115,
                  116,
                  97,
                  116,
                  117,
                  115
                ]
              },
              {
                "kind": "account",
                "path": "user"
              }
            ]
          }
        },
        {
          "name": "mint",
          "writable": true,
          "relations": [
            "distributor"
          ]
        },
        {
          "name": "user",
          "writable": true,
          "signer": true
        },
        {
          "name": "user_token_account",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "account",
                "path": "user"
              },
              {
                "kind": "const",
                "value": [
                  6,
                  221,
                  246,
                  225,
                  215,
                  101,
                  161,
                  147,
                  217,
                  203,
                  225,
                  70,
                  206,
                  235,
                  121,
                  172,
                  28,
                  180,
                  133,
                  237,
                  95,
                  91,
                  55,
                  145,
                  58,
                  140,
                  245,
                  133,
                  126,
                  255,
                  0,
                  169
                ]
              },
              {
                "kind": "account",
                "path": "mint"
              }
            ],
            "program": {
              "kind": "const",
              "value": [
                140,
                151,
                37,
                143,
                78,
                36,
                137,
                241,
                187,
                61,
                16,
                41,
                20,
                142,
                13,
                131,
                11,
                90,
                19,
                153,
                218,
                255,
                16,
                132,
                4,
                142,
                123,
                216,
                219,
                233,
                248,
                89
              ]
            }
          }
        },
        {
          "name": "token_program",
          "address": "TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb"
        },
        {
          "name": "associated_token_program",
          "address": "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "proof",
          "type": {
            "vec": {
              "array": [
                "u8",
                32
              ]
            }
          }
        }
      ]
    },
    {
      "name": "initialize",
      "discriminator": [
        175,
        175,
        109,
        31,
        13,
        152,
        155,
        237
      ],
      "accounts": [
        {
          "name": "distributor",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  100,
                  105,
                  115,
                  116,
                  114,
                  105,
                  98,
                  117,
                  116,
                  111,
                  114
                ]
              },
              {
                "kind": "account",
                "path": "mint"
              },
              {
                "kind": "account",
                "path": "authority"
              }
            ]
          }
        },
        {
          "name": "mint",
          "writable": true
        },
        {
          "name": "authority",
          "writable": true,
          "signer": true
        },
        {
          "name": "token_program",
          "address": "TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb"
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "merkle_root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        }
      ]
    },
    {
      "name": "update",
      "discriminator": [
        219,
        200,
        88,
        176,
        158,
        63,
        253,
        127
      ],
      "accounts": [
        {
          "name": "distributor",
          "writable": true,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": [
                  100,
                  105,
                  115,
                  116,
                  114,
                  105,
                  98,
                  117,
                  116,
                  111,
                  114
                ]
              },
              {
                "kind": "account",
                "path": "distributor.mint",
                "account": "Distributor"
              },
              {
                "kind": "account",
                "path": "distributor.authority",
                "account": "Distributor"
              }
            ]
          }
        },
        {
          "name": "authority",
          "signer": true,
          "relations": [
            "distributor"
          ]
        }
      ],
      "args": [
        {
          "name": "new_merkle_root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "ClaimStatus",
      "discriminator": [
        22,
        183,
        249,
        157,
        247,
        95,
        150,
        96
      ]
    },
    {
      "name": "Distributor",
      "discriminator": [
        90,
        90,
        217,
        147,
        6,
        32,
        135,
        4
      ]
    }
  ],
  "events": [
    {
      "name": "Claimed",
      "discriminator": [
        217,
        192,
        123,
        72,
        108,
        150,
        248,
        33
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidProof",
      "msg": "Invalid Merkle proof"
    },
    {
      "code": 6001,
      "name": "AlreadyClaimed",
      "msg": "Address has already claimed"
    }
  ],
  "types": [
    {
      "name": "ClaimStatus",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "user",
            "type": "pubkey"
          },
          {
            "name": "claimed",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "Claimed",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "user",
            "type": "pubkey"
          },
          {
            "name": "mint",
            "type": "pubkey"
          },
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "Distributor",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "merkle_root",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "mint",
            "type": "pubkey"
          },
          {
            "name": "authority",
            "type": "pubkey"
          }
        ]
      }
    }
  ]
};
export type ILDType = {
  "address": string;
    "metadata": {
    "name": "token_distribution",
      "version": "0.1.0",
      "spec": "0.1.0",
      "description": "Created with Anchor"
  },
  "instructions": [
    {
      "name": "claim",
      "discriminator": number[],
      "accounts": [
        {
          "name": "distributor",
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": number[]
              },
              {
                "kind": "account",
                "path": "distributor.mint",
                "account": "Distributor"
              },
              {
                "kind": "account",
                "path": "distributor.authority",
                "account": "Distributor"
              }
            ]
          }
        },
        {
          "name": "claim_status",
          "writable": boolean,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": number[]
              },
              {
                "kind": "account",
                "path": "user"
              }
            ]
          }
        },
        {
          "name": "mint",
          "writable": boolean,
          "relations": [
            "distributor"
          ]
        },
        {
          "name": "user",
          "writable": boolean,
          "signer": boolean
        },
        {
          "name": "user_token_account",
          "writable": boolean,
          "pda": {
            "seeds": [
              {
                "kind": "account",
                "path": "user"
              },
              {
                "kind": "const",
                "value": number[]
              },
              {
                "kind": "account",
                "path": "mint"
              }
            ],
            "program": {
              "kind": "const",
              "value": number[]
            }
          }
        },
        {
          "name": "token_program",
          "address": "TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb"
        },
        {
          "name": "associated_token_program",
          "address": "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "proof",
          "type": {
            "vec": {
              "array": [
                "u8",
                32
              ]
            }
          }
        }
      ]
    },
    {
      "name": "initialize",
      "discriminator": number[],
      "accounts": [
        {
          "name": "distributor",
          "writable": boolean,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": number[]
              },
              {
                "kind": "account",
                "path": "mint"
              },
              {
                "kind": "account",
                "path": "authority"
              }
            ]
          }
        },
        {
          "name": "mint",
          "writable": boolean
        },
        {
          "name": "authority",
          "writable": boolean,
          "signer": boolean
        },
        {
          "name": "token_program",
          "address": "TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb"
        },
        {
          "name": "system_program",
          "address": "11111111111111111111111111111111"
        }
      ],
      "args": [
        {
          "name": "merkle_root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        }
      ]
    },
    {
      "name": "update",
      "discriminator": number[],
      "accounts": [
        {
          "name": "distributor",
          "writable": boolean,
          "pda": {
            "seeds": [
              {
                "kind": "const",
                "value": number[]
              },
              {
                "kind": "account",
                "path": "distributor.mint",
                "account": "Distributor"
              },
              {
                "kind": "account",
                "path": "distributor.authority",
                "account": "Distributor"
              }
            ]
          }
        },
        {
          "name": "authority",
          "signer": boolean,
          "relations": [
            "distributor"
          ]
        }
      ],
      "args": [
        {
          "name": "new_merkle_root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        }
      ]
    }
  ],
    "accounts": [
    {
      "name": "ClaimStatus",
      "discriminator": number[]
    },
    {
      "name": "Distributor",
      "discriminator": number[]
    }
  ],
    "events": [
    {
      "name": "Claimed",
      "discriminator": number[]
    }
  ],
    "errors": [
    {
      "code": 6000,
      "name": "InvalidProof",
      "msg": "Invalid Merkle proof"
    },
    {
      "code": 6001,
      "name": "AlreadyClaimed",
      "msg": "Address has already claimed"
    }
  ],
    "types": [
    {
      "name": "ClaimStatus",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "user",
            "type": "pubkey"
          },
          {
            "name": "claimed",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "Claimed",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "user",
            "type": "pubkey"
          },
          {
            "name": "mint",
            "type": "pubkey"
          },
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "Distributor",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "merkle_root",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "mint",
            "type": "pubkey"
          },
          {
            "name": "authority",
            "type": "pubkey"
          }
        ]
      }
    }
  ]
};

