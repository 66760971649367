import {WalletStore} from "@heavy-duty/wallet-adapter";

export class ConnectWallet {
  static readonly type = '[Core] ConnectWallet';
  constructor(public publicKey: any, public wallet: any) {}
}
export class DisconnectWallet {
  static readonly type = '[Core] DisconnectWallet';
  constructor(

  ) {}
}

export class SecureConnect {
  static readonly type = '[Core] SecureConnect';
  constructor(public address: string, public walletStore: any) {
  }
}

export class GetUser {
  static readonly type = '[Core] GetUser';
  constructor(public address: string, public signature: any) {
  }
}
export class GetNonce {
  static readonly type = '[Core] GetNonce';
  constructor(public address: string) {
  }
}

export class ClearStore {
  static readonly type = '[Core] ClearStore';
  constructor() {
  }
}
export class DeleteStateMobile {
  static readonly type = '[Core] DeleteStateMobile';
  constructor() {
  }
}
export class DeleteContractMobile {
  static readonly type = '[Core] DeleteContractMobile';
  constructor() {
  }
}

export class GetLoyaltyData {
  static readonly type = '[Core] GetLoyaltyData';
  constructor() {
  }
}

export class GetMerkleProof {
  static readonly type = '[Core] GetMerkleProof';
  constructor(public wallet: any, public address: string, public points: number, public isMobile: boolean) {
  }
}

export class ExecuteProgramMethod {
  static readonly type = '[Core] ExecuteProgramMethod';
  constructor(public wallet: any, public address: string,
              public points: number, public proof: any, public isMobile: boolean) {
  }
}


export class SetSuccessStatus {
  static readonly type = '[Core] SetSuccessStatus';
  constructor() {
  }
}
